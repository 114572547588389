import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Table, TableBody, TableCell, TableHead, TableRow, Button, Box } from '@mui/material';

const LoadListData = () => {
  const { loadlistId, userId } = useParams(); // Get the loadlist ID from the URL params
  const [loadlistData, setLoadlistData] = useState([]); // State to store the fetched data
  const [userData, setUserData] = useState([]); // State to store the fetched data
  const [loadDate, setLoadDate] = useState(''); // State to store the load_date
  const [startingMileage, setStartingMileage] = useState(localStorage.getItem('startingMileage') || 0); // Load from localStorage
  const [endingMileage, setEndingMileage] = useState(localStorage.getItem('endingMileage') || 0); // Load from localStorage
  const [totalAmount, setTotalAmount] = useState(0); // State for Total Amount
  const [loadlistDetails, setLoadlistDetails] = useState([]); // State to store the full loadlist_detail objects
  const navigate = useNavigate(); // Hook to navigate to the detailed page

  useEffect(() => {
    fetchLoadlistData();
    fetchExistingDetails(); // Fetch full loadlist_detail objects
    fetchUserData();
  }, [loadlistId]);

  const fetchLoadlistData = async () => {
    try {
      const response = await fetch(`/api/loadlistdata/${loadlistId}/${userId}`); // API call to get data by loadlist ID
      const data = await response.json();
      setLoadlistData(data); // Update state with the fetched data
      const total = data.reduce((sum, row) => sum + (row.load_amount || 0), 0);
      setTotalAmount(total); // Set the total load amount
      const loadlistResponse = await fetch(`/api/loadlist/${loadlistId}`); // Fetch the loadlist details
      const loadlist = await loadlistResponse.json();
      setLoadDate(loadlist.load_date); // Set the load_date in the state
    } catch (error) {
      console.error('Error fetching loadlist data:', error);
    }
  };

  const fetchExistingDetails = async () => {
    try {
      const response = await fetch(`/api/existing-loadlist-details/${loadlistId}`); // API call to fetch full loadlist_detail objects
      const details = await response.json();
      setLoadlistDetails(details); // Store the full details in state
    } catch (error) {
      console.error('Error fetching existing loadlist details:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      console.log('userid: ' + userId)
      const response = await fetch(`/api/users/${userId}`); // API call to fetch full loadlist_detail objects
      const user = await response.json();
      console.log(user)
      setUserData(user); // Store the full details in state
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const rowExistsInDetails = (rowId) => {
    return loadlistDetails.some(detail => detail.loadlist_data_id === rowId);
  };

  const handleCompleteLoading = async () => {
    if (startingMileage === '' || parseInt(startingMileage) === 0) {
      alert('Starting Mileage must not be 0.');
      return;
    }
  
    if (endingMileage === '' || parseInt(endingMileage) === 0) {
      alert('Ending Mileage must not be 0.');
      return;
    }
  
    if (parseInt(startingMileage) >= parseInt(endingMileage)) {
      alert('Starting Mileage must be less than Ending Mileage.');
      return;
    }

    const allRowsHaveDetails = loadlistData.every(row => rowExistsInDetails(row.id));
    if (!allRowsHaveDetails) {
      alert('All load list rows must have completed details.');
      return;
    }

    const totalLoadedAmount = loadlistDetails.reduce((sum, detail) => sum + (detail.amount_loaded || 0), 0);
    if (totalLoadedAmount !== totalAmount) {
      alert(`Amount Loaded (${totalLoadedAmount}) does not match the Total Amount (${totalAmount})`);
      return;
    }

    const summaryData = {
      loadlist_id: loadlistId,
      startingMileage: parseInt(startingMileage),
      endingMileage: parseInt(endingMileage),
      totalAmount: totalAmount,
      userId: userId
    };
  
    try {
      const response = await fetch('/api/save-loadlist-summary', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(summaryData),
      });

      const result = await response.json();
      if (result.success) {
        localStorage.setItem('startingMileage', 0); // Save to localStorage
        localStorage.setItem('endingMileage', 0); // Save to localStorage
        alert('Loading summary saved successfully.');
        navigate('/loadselect');
      } else {
        console.error('Failed to save summary:', result.error);
        alert('Failed to save summary.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while saving the summary.');
    }
  };

  const handleStartingMileageChange = (e) => {
    const value = e.target.value;
    setStartingMileage(value);
    localStorage.setItem('startingMileage', value); // Save to localStorage
  };

  const handleEndingMileageChange = (e) => {
    const value = e.target.value;
    setEndingMileage(value);
    localStorage.setItem('endingMileage', value); // Save to localStorage
  };

  const handleRowClick = (rowData) => {
    var existingData = loadlistDetails.find(detail => detail.loadlist_data_id === rowData.id);
    navigate(`/loadlistdetail/${loadlistId}/${userId}/${rowData.id}`, { state: { rowData, existingData } });
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('T')[0].split('-'); // Split by 'T' first to remove the time part
    return `${month}/${day}/${year}`; // Return in MM/DD/YYYY format
  };

  const getRowColor = (row) => {
    if (rowExistsInDetails(row.id)){
       var rowDetailData = loadlistDetails.find(detail => detail.loadlist_data_id === row.id);
       if (rowDetailData){
        if(rowDetailData.amount_loaded != row.load_amount){
          console.log(rowDetailData)
          return 'yellow';
        }
       }
        return 'lightgreen';
    }else{
      return 'transparent';
    }
  }

  return (
    <Container maxWidth="md" sx={styles.container}>
      <Typography variant="h4" gutterBottom>
        Load List for: {formatDate(loadDate)}
      </Typography>
      
      <Box sx={styles.fieldContainer}>
        <TextField
          label="Total Amount"
          value={totalAmount}
          InputProps={{ readOnly: true }}
          variant="outlined"
          fullWidth
          sx={styles.readOnlyField}
        />
      </Box>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Starting Mileage"
          value={startingMileage}
          onChange={handleStartingMileageChange}
          variant="outlined"
          fullWidth
          sx={styles.inputField}
        />
      </Box>

      <Table sx={{ marginBottom: '20px', cursor: 'pointer' }}>
        <TableHead>
          <TableRow>
            <TableCell>Location Name</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Load Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadlistData.map((row, index) => (
            <TableRow
              key={index}
              onClick={() => handleRowClick(row)}
              sx={{
                cursor: 'pointer',
                backgroundColor: getRowColor(row),
              }}
            >
              <TableCell>{row.location_name}</TableCell>
              <TableCell>{row.balance}</TableCell>
              <TableCell>{row.load_amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Ending Mileage"
          value={endingMileage}
          onChange={handleEndingMileageChange}
          variant="outlined"
          fullWidth
          sx={styles.inputField}
        />
      </Box>

      <Button
        onClick={handleCompleteLoading}
        variant="contained"
        color="primary"
        fullWidth
        sx={styles.saveButton}
      >
        Complete Loading
      </Button>
    </Container>
  );
};

// Styles for MUI's `sx` prop
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial',
    textAlign: 'center',
  },
  fieldContainer: {
    marginBottom: '20px',
  },
  readOnlyField: {
    backgroundColor: '#cce6ff',
    fontSize: '1.2rem', // Larger text for readability
  },
  inputField: {
    backgroundColor: '#f8d7da',
    fontSize: '1.2rem', // Larger input text for readability
  },
  saveButton: {
    padding: '15px',
    backgroundColor: '#007bff',
    fontWeight: 'bold',
    fontSize: '1.2rem', // Larger button text
  },
};

export default LoadListData;
