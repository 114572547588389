import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box, Container } from '@mui/material';

const HomePage = () => {
  const navigate = useNavigate(); // Hook for navigation

  // Handlers for button clicks
  const handleUploadClick = () => {
    navigate('/upload'); // Navigate to the Upload LoadList page
  };

  const handleLoadingClick = () => {
    navigate('/loadselect'); // Navigate to the Loading page
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Typography variant="h4" align="center" gutterBottom>
        Welcome to ATM Load Management
      </Typography>

      <Box sx={styles.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={handleUploadClick}
          sx={styles.button}
        >
          Upload Load List
        </Button>

        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={handleLoadingClick}
          sx={styles.button}
        >
          Start Loading
        </Button>
      </Box>
    </Container>
  );
};

// Styles for the component using MUI's `sx` prop
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '20px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    marginTop: '30px',
  },
  button: {
    padding: '15px',
    fontSize: '16px',
  },
};

export default HomePage;
