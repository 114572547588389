import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box } from '@mui/material';

const LoadListDetail = () => {
  const { loadlistId, userId, rowId  } = useParams();
  const location = useLocation();
  const { rowData, existingData } = location.state;

  const [rejectCount, setRejectCount] = useState(existingData?.reject_amount || rowData.rejected || 0);
  const [trialCassetteTotal, setTrialCassetteTotal] = useState(existingData?.trial_cassette_total || 0);
  const [amountLoaded, setAmountLoaded] = useState(existingData?.amount_loaded || 0);
  const [locationAddress, setLocationAddress] = useState('N/A');
  const [comment, setComment] = useState(''); // State for comment
  const [showCommentBox, setShowCommentBox] = useState(false); // State to conditionally render comment box

  const navigate = useNavigate();

  useEffect(() => {
    fetchMasterData();
  }, []);

  const fetchMasterData = async () => {
    try {
      const response = await fetch(`/api/atm_master_data/${rowData.terminal_id}`);
      const master_data = await response.json();
      setLocationAddress(
        `${master_data[0].address}, ${master_data[0].city}, ${master_data[0].state}, ${master_data[0].zip}`
      );
    } catch (error) {
      console.error('Error fetching master data:', error);
    }
  };

  const handleSave = async () => {
    // Validation checks
    if (trialCassetteTotal < 0) {
      alert('Trial Cassette Total must be 0 or greater.');
      return;
    }

    // Check if the comment is required and validate
    if (comment.trim() === '' && amountLoaded != rowData.load_amount) {
      setShowCommentBox(true);
      alert('Please provide a comment explaining the discrepancy in Load Amount.');
      return;
    }

    const loadlistData = {
      loadlist_id: loadlistId,
      loadlist_data_id: rowId,
      trial_cassette_total: trialCassetteTotal,
      reject_amount: rejectCount,
      amount_loaded: amountLoaded,
      comment: showCommentBox ? comment : null, // Add comment only if necessary
    };

    try {
      const response = await fetch('/api/save-loadlist-detail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loadlistData),
      });

      const result = await response.json();
      if (result.success) {
        navigate(`/loadlistdata/${loadlistId}/${userId}`);
      } else {
        console.error('Failed to save data:', result.error);
        alert('Failed to save data.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while saving data.');
    }
  };

  const handleTransfer = async () => {
    navigate('/transfer', {
      state: {
        loadlistDataId: rowId,
        locationName: rowData.location_name,
        loadAmount: rowData.load_amount,
        userId: userId,
        loadlistId: loadlistId
      },
    });
    
  };

  // Handle amountLoaded change and determine whether to show comment box
  const handleAmountLoadedChange = (value) => {
    setAmountLoaded(value);
    setShowCommentBox(value !== rowData.load_amount); // Show comment box if amounts don't match
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Typography variant="h4" sx={styles.headerText}>
        Terminal ID: {rowData.terminal_id}
      </Typography>
     
      {/* Location Info */}
      <Box sx={styles.fieldContainer}>
        <TextField
          label="Location Name"
          value={rowData.location_name}
          InputProps={{ readOnly: true }}
          variant="outlined"
          fullWidth
          sx={styles.labelField}
          InputLabelProps={{ style: styles.labelText }}
          inputProps={{ style: styles.inputText }}
        />
      </Box>

       {/* TRansfer Button */}
       <Button
        onClick={handleTransfer}
        variant="contained"
        color="secondary"
        fullWidth
      >
        Transfer
      </Button>

      <Box sx={styles.fieldContainer}>
        <Typography variant="h4" sx={styles.subheaderText}>
          Address:
        </Typography>
        <Typography variant="outlined" sx={styles.addressText}>
          {locationAddress}
        </Typography>
      </Box>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Cash Balance"
          value={rowData.balance}
          InputProps={{ readOnly: true }}
          variant="outlined"
          fullWidth
          sx={styles.labelField}
          InputLabelProps={{ style: styles.labelText }}
          inputProps={{ style: styles.inputText }}
        />
      </Box>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Load Amount"
          value={rowData.load_amount}
          InputProps={{ readOnly: true }}
          variant="outlined"
          fullWidth
          sx={styles.labelField}
          InputLabelProps={{ style: styles.labelText }}
          inputProps={{ style: styles.inputText }}
        />
      </Box>

      {/* Additional Input Fields */}
      <Box sx={styles.fieldContainer}>
        <TextField
          label="Trial Cassette Total"
          type="number"
          value={trialCassetteTotal}
          onChange={(e) => setTrialCassetteTotal(parseInt(e.target.value))}
          variant="outlined"
          fullWidth
          sx={styles.input}
          InputLabelProps={{ style: styles.labelText }}
          inputProps={{ style: styles.inputText }}
        />
      </Box>

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Reject Amount"
          type="number"
          value={rejectCount}
          onChange={(e) => setRejectCount(parseInt(e.target.value) || 0)}
          variant="outlined"
          fullWidth
          sx={styles.input}
          InputLabelProps={{ style: styles.labelText }}
          inputProps={{ style: styles.inputText }}
        />
      </Box>

      {rejectCount > 0 && (
        <Typography sx={styles.notice}>
          Due to Rejects, perform Cassette Total and Add Cash
        </Typography>
      )}

      <Box sx={styles.fieldContainer}>
        <TextField
          label="Amount Actually Loaded"
          type="number"
          value={amountLoaded}
          onChange={(e) => handleAmountLoadedChange(parseInt(e.target.value))}
          variant="outlined"
          fullWidth
          sx={styles.input}
          InputLabelProps={{ style: styles.labelText }}
          inputProps={{ style: styles.inputText }}
        />
      </Box>

      {/* Conditional Comment Box */}
      {showCommentBox && (
        <Box sx={styles.fieldContainer}>
          <TextField
            label="Load Amount Discrepency. Why?"
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            sx={styles.input}
            InputLabelProps={{ style: styles.labelText }}
            inputProps={{ style: styles.inputText }}
          />
        </Box>
      )}

      {/* Save Button */}
      <Button
        onClick={handleSave}
        variant="contained"
        color="primary"
        fullWidth
        sx={styles.saveButton}
      >
        Save
      </Button>
    </Container>
  );
};

// Styles for MUI's `sx` prop
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial',
    textAlign: 'center',
  },
  headerText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  subheaderText: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  addressText: {
    fontSize: '1rem',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fieldContainer: {
    marginBottom: '20px',
  },
  labelField: {
    backgroundColor: '#cce6ff',
  },
  labelText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  inputText: {
    fontSize: '1.2rem',
  },
  input: {
    backgroundColor: '#f8d7da',
  },
  notice: {
    marginBottom: '20px',
    color: 'red',
    fontWeight: 'bold',
  },
  saveButton: {
    padding: '15px',
    backgroundColor: '#007bff',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
};

export default LoadListDetail;
