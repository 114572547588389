import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Table, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material';

const LoadListApp = () => {
  const [data, setData] = useState([]);
  const [sheetName, setSheetName] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);


const fetchUsers = async () => {
    try {
      const response = await fetch('/api/users'); // Call the API to get load dates
      const data = await response.json();
      setAllUsers(data); // Update the state with fetched load dates
      console.log(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetNames = workbook.SheetNames;
      const lastSheetName = sheetNames[sheetNames.length - 1] !== 'Sheet1'
        ? sheetNames[sheetNames.length - 1]
        : sheetNames[sheetNames.length - 2];

      const lastSheet = workbook.Sheets[lastSheetName];
      setSheetName(lastSheetName);

      const excelData = XLSX.utils.sheet_to_json(lastSheet, { header: 1 });
      let headers = null;
      const mappedData = [];
      let loadAmountTotal = 0;
      
      excelData.forEach((row, rowIndex) => {
        if (!headers) {
          const lowercaseRow = row.map((cell) =>
            cell ? cell.toString().toLowerCase() : ""
          );

          headers = {
            terminalId: lowercaseRow.indexOf("term id") >= 0
              ? lowercaseRow.indexOf("term id")
              : lowercaseRow.indexOf("terminal id"),
            locationName: lowercaseRow.indexOf("location name") >= 0
              ? lowercaseRow.indexOf("location name")
              : lowercaseRow.indexOf("name"),
            order: lowercaseRow.indexOf("order"),
            balance: lowercaseRow.indexOf("balance") >= 0
              ? lowercaseRow.indexOf("balance")
              : lowercaseRow.indexOf("bal"),
            loadAmount: lowercaseRow.indexOf("load") >= 0
              ? lowercaseRow.indexOf("load")
              : lowercaseRow.indexOf("ld"),
            loader: lowercaseRow.indexOf("loader") // Add "loader" column detection
          };
        } else if (row[headers.terminalId]) {
          const hasAllData = [headers.terminalId, headers.locationName, headers.order, headers.balance, headers.loadAmount]
            .every((index) => row[index] !== undefined && row[index] !== '');

            var loaderName = row[headers.loader] || null;
            if (loaderName != null){
              loaderName = loaderName.toString().toLowerCase()
            }
            var matchedUser = allUsers.find(obj => obj.name.toString().toLowerCase() === loaderName);
            var userId = matchedUser ? matchedUser.id : -1;
            
            if (hasAllData) {
            const loadAmount = parseFloat(row[headers.loadAmount]) || 0;
            mappedData.push({
              'Terminal ID': row[headers.terminalId],
              'Location Name': row[headers.locationName],
              'Order': row[headers.order],
              'Balance': row[headers.balance],
              'Load Amount': loadAmount,
              'Loader': loaderName, // Store loader as nullable
              'Rejected': 0,
              'User ID': userId
            });

            loadAmountTotal += loadAmount;
          }
        }
      });
      
      setData(mappedData);
      setTotalAmount(loadAmountTotal);
    };

    reader.readAsBinaryString(file);
  };



  const convertSheetNameToDate = (sheetName) => {
    const [month, day, year] = sheetName.split('.').map(Number);
    const fullYear = `20${year}`;
    return `${fullYear}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  const triggerUpload = () => {
    document.getElementById('fileInput').click();
  };

  const handleSave = async () => {
    if (!sheetName) {
      console.error('No sheet name available to extract date');
      return;
    }

    if(data.some(obj => obj["Loader"] == null)){
      alert('Every Load list row needs an assigned Loader.  Update the load list and re-upload.');
      return;
    }

    if(data.some(obj => obj["User ID"] == -1)){
      var badUser = data.find(obj => obj["User ID"] == -1);
      alert(badUser["Loader"] + ' is not a valid Loader in the system.');
      return;
    }

    const loadDate = convertSheetNameToDate(sheetName);

    
    try {
      const response = await fetch('/api/save-loadlist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data, load_date: loadDate })
      });

      const result = await response.json();
      if (result.success) {
        console.log('Data saved successfully!');
        alert('Load List Uploaded Successfully!');
        navigate('/');
      } else {
        alert('Failed to save data');
        console.error('Failed to save data:', result.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Unknown Error!');
    }
  };

  return (
    <Container maxWidth="md" sx={styles.container}>
      <Typography variant="h4" align="center" gutterBottom>Load List Uploader</Typography>
      <Box>
        <input
          type="file"
          id="fileInput"
          accept=".xlsx, .xls"
          onChange={handleUpload}
          style={{ display: 'none' }}
        />
        <Button variant="contained" color="primary" onClick={triggerUpload}>
          Upload
        </Button>
      </Box>

      {sheetName && (
        <>
          <Typography variant="h6" sx={{ marginTop: '20px' }}>
            Sheet Loaded: {sheetName}
          </Typography>
          <Typography variant="h6" sx={{ marginTop: '10px', color: 'green' }}>
            Total Load Amount: ${totalAmount.toFixed(2)}
          </Typography>
        </>
      )}

      <Typography variant="h6" sx={{ marginTop: '30px' }}>Data View</Typography>
      <Table sx={{ marginTop: '10px' }}>
        <TableHead>
          <TableRow>
            <TableCell>Terminal ID</TableCell>
            <TableCell>Location Name</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Load Amount</TableCell>
            <TableCell>Loader</TableCell>
            <TableCell>User ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row['Terminal ID']}</TableCell>
              <TableCell>{row['Location Name']}</TableCell>
              <TableCell>{row['Order']}</TableCell>
              <TableCell>{row['Balance']}</TableCell>
              <TableCell>{row['Load Amount']}</TableCell>
              <TableCell>{row['Loader']}</TableCell>
              <TableCell>{row['User ID']}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Button variant="contained" color="secondary" onClick={handleSave} sx={{ marginTop: '20px' }}>
        Save
      </Button>
    </Container>
  );
};

// Styles for MUI's `sx` prop
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
};

export default LoadListApp;
