import React, { useState, useEffect } from 'react';
import { Container, Typography, Select, MenuItem, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const TransferPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loadlistDataId, locationName, loadAmount, userId, loadlistId } = location.state || {}; // Retrieve passed state
  const [allUsers, setUsers] = useState([]); // State for all users
  const [dropdownValue, setDropdownValue] = useState(''); // State for dropdown selection

  // Fetch all users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/users'); // Call the API to get users
      const data = await response.json();
      setUsers(data); // Update the state with fetched users
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value); // Set selected user ID
  };

  const handleTransfer = async () => {
    if (!dropdownValue) {
      alert('Please select a loader to transfer to.');
      return;
    }

    console.log(dropdownValue);

    try {
      const response = await fetch(`/api/loadlistdata/${loadlistDataId}/transfer/${dropdownValue}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (result.success) {
        navigate(`/loadlistdata/${loadlistId}/${userId}`);
      } else {
        console.error('Failed to transfer:', result.error);
        alert('Failed to transfer.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while transferring.');
    }
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Typography variant="h4" gutterBottom>
        Transfer Details
      </Typography>
      <Box sx={styles.fieldContainer}>
        <Typography variant="h6">Location Name:</Typography>
        <Typography>{locationName || 'N/A'}</Typography>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Typography variant="h6">Load Amount:</Typography>
        <Typography>{loadAmount || 'N/A'}</Typography>
      </Box>
      <Box sx={styles.fieldContainer}>
        <Select
          value={dropdownValue}
          onChange={handleDropdownChange}
          fullWidth
          displayEmpty
          sx={styles.dropdown}
        >
          <MenuItem value="" disabled>
            Select Loader to Transfer To
          </MenuItem>
          {allUsers.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Button variant="contained" onClick={handleTransfer} sx={styles.transferButton}>
        Transfer
      </Button>
    </Container>
  );
};

// Styles
const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Arial',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  fieldContainer: {
    marginBottom: '20px',
  },
  dropdown: {
    backgroundColor: '#f1f1f1',
    marginTop: '10px',
  },
  transferButton: {
    padding: '10px',
    fontWeight: 'bold',
    backgroundColor: '#007bff',
    color: '#ffffff',
    fontSize: '1.2rem',
    marginTop: '20px',
  },
};

export default TransferPage;
