import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

const LoadListSelect = () => {
  const [loadDates, setLoadDates] = useState([]); // State to store the dates
  const [users, setUsers] = useState([]); // State to store the dates
  const [selectedDate, setSelectedDate] = useState(''); // State to store selected date
  const [selectedUser, setSelectedUser] = useState(''); // State to store selected date
  const navigate = useNavigate(); // Hook for navigation

  // Fetch load dates when the component mounts
  useEffect(() => {
    fetchLoadDates();
    fetchUsers();
  }, []);

  // Function to fetch load dates from the API
  const fetchLoadDates = async () => {
    try {
      const response = await fetch('/api/loadlists'); // Call the API to get load dates
      const data = await response.json();
      setLoadDates(data); // Update the state with fetched load dates
      console.log(data);
    } catch (error) {
      console.error('Error fetching load dates:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/users'); // Call the API to get load dates
      const data = await response.json();
      setUsers(data); // Update the state with fetched load dates
      console.log(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('T')[0].split('-'); // Split by 'T' first to remove the time part
    return `${month}/${day}/${year}`; // Return in MM/DD/YYYY format
  };
  

  // Handle dropdown selection change
  const handleChange = (e) => {
    setSelectedDate(e.target.value);
  };

    // Handle dropdown selection change
    const handleUserChange = (e) => {
      setSelectedUser(e.target.value);
    };

  // Handle button click to navigate to the data page
  const handleViewData = () => {
    if (selectedDate) {
      if (selectedUser){
        navigate(`/loadlistdata/${selectedDate}/${selectedUser}`); // Navigate to the new page with the selected loadlist ID and user
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Typography variant="h5" align="center" gutterBottom>
        Select a Load List by Date
      </Typography>

      {/* Dropdown */}
      <FormControl fullWidth sx={{ marginBottom: "20px" }}>
        <InputLabel id="load-date-label">Select a Date</InputLabel>
        <Select
          labelId="load-date-label"
          value={selectedDate}
          onChange={handleChange}
          label="Select a Date"
        >
          <MenuItem value="">
            <em>Select a date</em>
          </MenuItem>
          {loadDates.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {formatDate(item.load_date)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Dropdown */}
      <FormControl fullWidth sx={{ marginBottom: "20px" }}>
        <InputLabel id="load-date-label">Select a Loader</InputLabel>
        <Select
          labelId="loader-label"
          value={selectedUser}
          onChange={handleUserChange}
          label="Select a Loader"
        >
          <MenuItem value="">
            <em>Select a Loader</em>
          </MenuItem>
          {users.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Button to view associated data */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleViewData}
        disabled={!selectedDate}
      >
        Start Loading
      </Button>
    </Container>
  );
};

// Styles for MUI's `sx` prop
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '20px',
  },
};

export default LoadListSelect;
