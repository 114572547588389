import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LoadListApp from './LoadListApp'; // Import your LoadListApp component
import LoadListSelect from './LoadListSelect'; // Import your LoadListApp component
import LoadListData from './LoadListData'; // Import your LoadListApp component
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadListDetail from './LoadListDetail';
import HomePage from './HomePage';
import TransferPage from './TransferLoadListData';
import AddressSorterTest from './AddressSorterTest';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
      <Route path="/" element={<HomePage />} />  {/* Default route */}
      <Route path="/sorter" element={<AddressSorterTest />} /> 
      <Route path="/upload" element={<LoadListApp />} />  {/* Load List page */}
        <Route path="/loadselect" element={<LoadListSelect />} /> {/* New route */}
        <Route path="/transfer" element={<TransferPage />} /> {/* New route */}
        <Route path="/loadlistdata/:loadlistId/:userId" element={<LoadListData />} /> {/* New route to display data */}
        <Route path="/loadlistdetail/:loadlistId/:userId/:rowId" element={<LoadListDetail />} /> {/* New route to display data */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
