import React, { useState } from "react";

const AddressSorterTest = () => {
  const [userLocation, setUserLocation] = useState(null);
  const [sortedAddresses, setSortedAddresses] = useState([]);
  const [error, setError] = useState(null);

  // Hardcoded list of addresses
  const addresses = [
    "1600 Amphitheatre Parkway, Mountain View, CA",
    "1 Infinite Loop, Cupertino, CA",
    "345 Spear Street, San Francisco, CA",
    "701 1st Ave, Sunnyvale, CA"
  ];


  // Get user's current location
  const getUserLocation = () => {
    setUserLocation({ lat: 37.97922836975099, lng: -120.39186662777128 });
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const { latitude, longitude } = position.coords;
    //       setUserLocation({ lat: latitude, lng: longitude });
    //       setError(null);
    //     },
    //     (err) => setError("Unable to retrieve location")
    //   );
    // } else {
    //     console.log("setting default location for testing")
    //   //setError("Geolocation is not supported by your browser");
    // }
  };

  // Fetch distance matrix
  const getDistanceMatrix = async (origins, destinations) => {
    const originsString = origins.join("|");
    const destinationsString = destinations.join("|");
    
    try {
        const response = await fetch(`/api/distance-matrix?origins=${originsString}&destinations=${destinationsString}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });
  
        const data = await response.json();
        console.log(data)
        if (data.success) {
            alert('api successful');
            return data.rows.map((row) => row.elements.map((el) => el.distance.value));
        } else {
          console.error(data.error);
          alert('api failed.');
        }
      } catch (error) {
        console.error(error);
        alert('An error occurred while fetching.');
      }


    // const response = await fetch(url);
    // const data = await response.json();

    // if (data.status !== "OK") {
    //   throw new Error("Failed to fetch distance matrix");
    // }

  };

  // Solve the Traveling Salesman Problem
  const solveTSP = (distanceMatrix, startingIndex) => {
    const n = distanceMatrix.length;
    const visited = Array(n).fill(false);
    visited[startingIndex] = true;

    const route = [startingIndex];
    let current = startingIndex;

    for (let i = 0; i < n - 1; i++) {
      let next = -1;
      let shortestDistance = Infinity;

      for (let j = 0; j < n; j++) {
        if (!visited[j] && distanceMatrix[current][j] < shortestDistance) {
          next = j;
          shortestDistance = distanceMatrix[current][j];
        }
      }

      visited[next] = true;
      route.push(next);
      current = next;
    }

    return route;
  };

  // Handle button click
  const handleSortAddresses = async () => {
    if (!userLocation) {
      setError("Please allow location access before sorting addresses.");
      return;
    }

    const allLocations = [
      `${userLocation.lat},${userLocation.lng}`,
      ...addresses
    ];

    try {
      const distanceMatrix = await getDistanceMatrix(allLocations, allLocations);
      const route = solveTSP(distanceMatrix, 0); // User's location as the starting point

      const sorted = route.slice(1).map((index) => addresses[index - 1]); // Exclude the user's location
      setSortedAddresses(sorted);
      setError(null);
    } catch (err) {
      setError("An error occurred while sorting addresses.");
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial" }}>
      <h1>Address Sorter</h1>
      <button onClick={getUserLocation} style={{ marginBottom: "10px" }}>
        Get Current Location
      </button>
      <button onClick={handleSortAddresses} style={{ marginLeft: "10px" }}>
        Sort Addresses
      </button>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {userLocation && (
        <p>
          Your location: {userLocation.lat}, {userLocation.lng}
        </p>
      )}
      <h2>Original Addresses:</h2>
      <ul>
        {addresses.map((address, index) => (
          <li key={index}>{address}</li>
        ))}
      </ul>
      {sortedAddresses.length > 0 && (
        <>
          <h2>Sorted Addresses:</h2>
          <ul>
            {sortedAddresses.map((address, index) => (
              <li key={index}>{address}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AddressSorterTest;
